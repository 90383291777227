import api from "@api";

export default {
    state: {
        homeDto: {
            subjectsNumber: 0,
            coursesNumber: 0,
            lessonsNumber: 0,
            questionsNumber: 0,
            ads: [],
            notifications: [],
            unPaidCode: {
                currentMonthCount: 0,
                allUnPaidCodesCount: 0,
                currentMonthPrice: 0,
            },
            paidCode: {
                currentMonthCount: 0,
                allPaidCodesCount: 0,
                currentMonthPrice: 0,
            },
            students: [],
            sellPoints: [],
        },
        yearDto: {
            imports: [],
            profits: 0,
        },
    },
    mutations: {
        SET_HOME(state, paylaod) {
            state.homeDto = paylaod;
        },
        SET_YEAR_REPORTS(state, paylaod) {
            state.yearDto = paylaod;
            console.log(state.homeDto);
        },
    },
    actions: {
        getHome({ commit }) {
            api.get(`Home/Dash/GetStatisticsHome`, ({ data }) => {
                commit("SET_HOME", data);
            });
        },
        getYearReports({ commit }, year) {
            api.get(
                `Home/Dash/GetProfitStatisticHome?year=${year}`,
                ({ data }) => {
                    commit("SET_YEAR_REPORTS", data);
                }
            );
        },
    },
};
