import { getSearchResultOnRow } from "@Ekcore/util";
import api from "@api";

export default {
    state: {
        dashUserList: [],
        isDialogOpen: false,
        dashUserStatus: [
            { value: false, text: "فعال", color: "success" },
            { value: true, text: "غير فعال", color: "danger" }
        ],
        dashUserRole: [
            { id: 'Admin', name: "Admin" },
            { id: 'DataEntry', name: "DataEntry" },
            { id: 'Accountant', name: "Accountant" },
        ],
        dtoDetailsDashUser: {
            subjectIds:[]
        },
        columns: [
            {
                label: " اسم المستخدم ",
                field: "userName"
            },
            {
                label: " الاسم الكامل ",
                field: "name"
            },
            {
                label: "رقم الموبايل",
                field: "phoneNumber"
            },
            {
                label: "البريد اإلكتروني ",
                field: "email"
            },
            {
                label: "تاريخ الإضافة",
                field: "dateCreated"
            },
            {
                label: "السماحية",
                field: "role"
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false
            }
        ],
    },
    getters: {
        dashList({ dashUserList }, getter, { filter }) {
            return dashUserList.filter(el => {
                return getSearchResultOnRow(filter, el);
            });
        }
    },
    mutations: {
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        }, 
        SET_USER_DTO(state, payload) {
            if (payload) state.dtoDetailsDashUser = { ...payload };
            else
                state.dtoDetailsDashUser = {
                    name: "",
                    userName: '',
                    email: '',
                    password: '',
                    address:'',
                    phoneNumber:'',
                    role:''
                };
        },
        Get_Dash_User(state, payload) {
            state.dashUserList = payload;
        },
        Add_Dash_User(state, payload) {
            state.dashUserList.unshift(payload);
        },
        Update_Dash_User(state, payload) {
            let dto = state.dashUserList.find(ele => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Delete_Dash_User(state, id) {
            state.dashUserList.splice(
                state.dashUserList.findIndex((item) => item.id == id),
                1
            );
        },
        Delete_Dash_Users(state, payload) {
            state.dashUserList = state.dashUserList.filter(
                el => !payload.includes(el.id)
            );
        },
        IsBlocked_Dash_User(state, payload) {
            state.dtoDetailsDashUser.isBlocked = payload;
        }
    },
    actions: {
        getDashUser({ commit }) {
            api.get("DashUser/Dash/GetAll", ({ data }) => {
                commit("Get_Dash_User", data);
            });
        },
        addDashUser({ commit }, payload) {
            api.post(
                "DashUser/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Dash_User", data);
                    commit("SET_USER_DTO");
                },
                {
                    success: "تم اضافة الحساب بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },
        deleteDashUser({ commit }, id) {
            api.delete(
                `DashUser/Dash/Delete?id=${id}`,
                ({ data }) => {
                    commit("Delete_Dash_User", data);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحساب المحدد",
                    success: "تم حذف الحساب بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                []
            );
        },
        deleteRangeDashUser({ commit }, ids) {
            api.delete(
                `DashUser/Dash/Delete`,
                () => {
                    commit("Delete_Dash_Users", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الحسابات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        updateDashUser({ commit }, payload) {
            api.post(
                "DashUser/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Dash_User", data);
                    commit("SET_USER_DTO");
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات المستخدم؟",
                    success: "تم تعديل المستخدم بنجاح",
                    error: "فشل التعديل.. يرجى إعادة المحاولة"
                }
            );
        },
        blockDashUser({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("IsBlocked_Dash_User", true);
                },
                {
                    success: "تم حظر المستخدم بنجاح",
                    error: "فشل حظر المستخدم ..يرجى اعادة المحاولة "
                }
            );
        },
        unBlockDashUser({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("IsBlocked_Dash_User", false);
                },
                {
                    success: "تم الغاء حظر المستخدم بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة "
                }
            );
        }
    },
};
