import { $themeBreakpoints } from "@themeConfig";
import { $themeConfig } from "@themeConfig";
import store from "@/store";

export default {
    namespaced: true,
    state: {
        isLoading: false,
        windowWidth: 0,
        domainHost: $themeConfig.app.domainPath,
        shallShowOverlay: false,
        lang: localStorage.getItem("lang") == "ar" ? 0 : 1,
        dashLang: localStorage.getItem("DashLang") == "ar" ? 1 : 2
    },
    getters: {
        currentBreakPoint: state => {
            const { windowWidth } = state;
            if (windowWidth >= $themeBreakpoints.xl) return "xl";
            if (windowWidth >= $themeBreakpoints.lg) return "lg";
            if (windowWidth >= $themeBreakpoints.md) return "md";
            if (windowWidth >= $themeBreakpoints.sm) return "sm";
            return "xs";
        },
        isLoading(state) {
            return state.isLoading;
        },
        domainHost(state) {
            return state.domainHost;
        },
        lang({ lang }) {
            return lang;
        },
        dashLang({ dashLang }) {
            return dashLang;
        }
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val;
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay =
                val !== undefined ? val : !state.shallShowOverlay;
        },
        Set_Lang(state, lang) {
            state.lang = lang;
        },
        SET_DASH_LANG(state, lang) {
            state.dashLang = lang;
        }
    },
    actions: {
        setLoading({ state }, is) {
            state.isLoading = is;
        },
        pageChanged(context, pageInfo) {
            const firstPageItem =
                pageInfo.pageLength * (pageInfo.currentPage - 1);
            return pageInfo.items.slice(
                firstPageItem,
                firstPageItem + pageInfo.pageLength
            );
        },
        filterItems({ state }, payload) {
            const { items, key } = payload;
            return store.getters[items].filter(item => {
                return (
                    item[key].indexOf(
                        state.createButtonAndGlobalSearch.value
                    ) != -1
                );
            });
        },
        setLang({ commit }, lang) {
            commit("Set_Lang", lang);
        },
        setDashLang({ commit }, lang) {
            commit("SET_DASH_LANG", lang);
        }
    }
};
