import api from "@api";
import router from "@/router";
import { getSearchResultOnRow } from "@/EK-core/util";
export default {
    state: {
        sellPointName: [],
        sellPointsList: [],
        sellPointDto: {},
        sellPointStatus: [
            { text: "محظور ", value: true, color: "danger" },
            { text: "فعال", value: false, color: "success" }
        ],
        statuscode: [
            { value: true, text: "فعال", color: "success" },
            { value: false, text: "غير فعال", color: "danger" }
        ],
        dtoUpdateSellPoint: {},
        columns: [
            {
                label: " اسم نقطة البيع  ",
                field: "name"
            },
            {
                label: " رقم الموبايل ",
                field: "phoneNumber"
            },
            {
                label: " العنوان ",
                field: "address"
            },
            {
                label: "عدد الرموز المولدة",
                type: "number",
                field: "codesCount"
            },
            {
                label: " تاريخ الاشتراك ",
                field: "dateCreated"
            },
            {
                label: " التفاصيل",
                field: "details"
            }
        ],
        columnsCode: [
            {
                label: " الرمز",
                field: "code"
            },
            {
                label: "تابعة ل ",
                field: "subjetsNames"
            },
            {
                label: "تاريخ التوليد",
                field: "startDate"
            },
            {
                label: " اسم الطالب",
                field: "studentName"
            },
            {
                label: "  حالة الإشتراك",
                field: "isActive"
            },
            {
                label: "القيمة المدفوعة",
                field: "price"
            },

            {
                label: "انتهاء الاشتراك",
                field: "endDate"
            },
            {
                label: "تفاصيل",
                field: "details"
            }
        ]
    },
    mutations: {
        SET_SELL_POINT(state, payload) {
            state.sellPointsList = payload;
        },
        SET_NAME_SELL_POINT(state, payload) {
            state.sellPointName = payload
        },
        RESET_SELL_POINTS_DTO(state) {
            state.sellPointDto = {
                name: "",
                phoneNumber: "",
                dateCreated: "",
                password: "",
                discountLimit: 0,
                moneyLimit: 0,
                address: "",
                canDiscount: false
            };
        },
        ADD_SELL_POINT(state, payload) {
            state.sellPointsList.unshift(payload);
        },
        GET_BYID_SELL_POINT(state, paylaod) {
            state.dtoUpdateSellPoint = { ...paylaod };
        },
        DELETE_SELL_POINTS(state, payload) {
            state.sellPointsList = state.sellPointsList.filter(
                el => !payload.includes(el.id)
            );
        },
        UPDATE_SELL_POINT(state, paylaod) {
            state.dtoUpdateSellPoint = { ...paylaod };
        },
        ISBLOCK_SELL_POINT(state, payload) {
            state.dtoUpdateSellPoint.isBlocked = payload;
        }
    },
    actions: {
        getAllSellPoints({ commit }) {
            api.get("SellPoint/Dash/GetAll", ({ data }) => {
                commit("SET_SELL_POINT", data);
            });
        },
        getNameSellPoints({ commit }) {
            api.get("SellPoint/Dash/GetNames", ({ data }) => {
                commit("SET_NAME_SELL_POINT", data);
            });
        },
        getByIdSellPoint({ commit }, id) {
            api.get(`SellPoint/Dash/GetById?id=${id}`, ({ data }) => {
                commit("GET_BYID_SELL_POINT", data);
            });
        },
        addSellPoint({ commit }, paylaod) {
            api.post(
                "SellPoint/Dash/Add",
                paylaod,
                ({ data }) => {
                    commit("ADD_SELL_POINT", data);
                },
                {
                    success: "تم اضافة هذه النقطة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة "
                }
            );
        },


        deleteRangeSellPoints({ commit }, ids) {
            api.delete(
                "SellPoint/Dash/Delete",
                () => {
                    commit("DELETE_SELL_POINTS", ids);
                    router.go(-1)
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف نقطة البيع المحددة",
                    success: "تم حذف هذه النقطة بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة "
                },
                ids
            );
        },
        updateSellPoint({ commit }, paylaod) {
            api.post(
                "SellPoint/Dash/Modify",
                paylaod,
                ({ data }) => {
                    commit("UPDATE_SELL_POINT", data);
                    router.push("/admin/sellpoints");
                },
                {
                    success: "تم تعديل هذه النقطة بنجاح",
                    error: "فشل التعديل ..يرجى اعادة المحاولة "
                }
            );
        },
        blockSellPoint({ commit }, id) {
            api.post(
                `User/Dash/Block?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_SELL_POINT", true);
                },
                {
                    success: "تم حظر هذه النقطة بنجاح",
                    error: "فشل الحظر  ..يرجى اعادة المحاولة "
                }
            );
        },
        unblockSellPoint({ commit }, id) {
            api.post(
                `User/Dash/UnBlock?id=${id}`,
                null,
                () => {
                    commit("ISBLOCK_SELL_POINT", false);
                },
                {
                    success: "تم الغاء حظر النقطة بنجاح",
                    error: "فشل الغاء الحظر  ..يرجى اعادة المحاولة "
                }
            );
        }
    },
    getters: {
        searchListSellPoints({ sellPointsList }, getters, { filter }) {
            let query = filter.searchDto.query;
            return sellPointsList.filter(ele => {
                return (
                    getSearchResultOnRow(filter, ele) ||
                    ele.address.indexOf(query) != -1 ||
                    ele.name.indexOf(query) != -1 ||
                    ele.phoneNumber == query
                );
            });
        }
    }
};
