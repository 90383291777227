var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{attrs:{"vid":_vm.vid,"name":_vm.name,"rules":_vm.faildRules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
var validated = ref.validated;
var touched = ref.touched;
return _c('b-form-group',_vm._b({staticClass:"ek-form-group",attrs:{"label-cols":_vm.labelCols,"label-for":'label-for-text-' + _vm.id}},'b-form-group',_vm.$attrs,false),[_c('b-input-group',{staticClass:"rounded",scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('b-input-group-text',[_c('unicon',{attrs:{"name":"calendar-alt","width":"18","height":"18","fill":"#B9B9C3"}})],1)]},proxy:true}],null,true)},[_c('flat-pickr',_vm._b({staticClass:"form-control text-left",class:{
          'border-danger': !valid && validated && touched,
        },attrs:{"id":'label-for-picker-range-' + _vm.id,"placeholder":_vm.placeholder,"state":_vm.faildRules ? (errors[0] ? false : valid ? true : null) : null,"dir":"ltr","config":_vm.config},on:{"on-change":_vm.onChange,"change":function($event){return _vm.$emit('change', $event)}},model:{value:(_vm.innerVal),callback:function ($$v) {_vm.innerVal=$$v},expression:"innerVal"}},'flat-pickr',_vm.$attrs,false))],1),(!valid && validated && touched)?_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }