import api from "@api";

export default {
    state: {
        hello: "hello Storey",
        previousExamsList: [],
        previousExamsName: [],
        previousExamDto: {
            year: 0,
            name: "",
            isFree: true,
            subjectId: "",
            semesterId: "",
            teacherId: "",
            lessonName: "",
            unitName: "",
            chapterName: "",
            PreviousExamType: "",
        },
        DetailsPreviousExams: {
            id: "",
            name: "",
            universityName: "",
            facultyName: "",
            semesterName: "",
            subjectName: "",
            teacherName: "",
            unitName: "",
            lessonName: "",
            chapterName: "",
            questionCount: 0,
            year: 3,
            isFree: true,
            dateCreated: "",
            questions: [],
        },
        filterDto: {
            PreviousExamType: "PreviousExam",
            subjectId: null,
            StudentType: 0,
        },
        subjectId: null,
        columns: [
            {
                label: "اسم البنك",
                field: "name",
            },
            {
                label: "الجامعة/المرحلة",
                field: "universityName",
            },
            {
                label: " الكلية/الصف",
                field: "facultyName",
            },
            {
                label: "عدد الاسئلة",
                field: "questionCount",
            },
            {
                label: "المادة",
                field: "subjectName",
            },
            {
                label: "الفصل",
                field: "semesterName",
            },
            {
                label: "الوحدة",
                field: "unitName",
            },
            {
                label: "القسم",
                field: "chapterName",
            },
            {
                label: "الدرس",
                field: "lessonName",
            },
            {
                label: "الحالة",
                field: "isFree",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
        columnsExam: [
            {
                label: "اسم البنك",
                field: "name",
            },
            {
                label: "الجامعة/المرحلة",
                field: "universityName",
            },
            {
                label: " الكلية/الصف",
                field: "facultyName",
            },
            {
                label: "عدد الاسئلة",
                field: "questionCount",
            },
            {
                label: "المادة",
                field: "subjectName",
            },
            {
                label: "الفصل",
                field: "semesterName",
            },
            {
                label: "الوحدة",
                field: "unitName",
            },
            {
                label: "القسم",
                field: "chapterName",
            },
            {
                label: "الدرس",
                field: "lessonName",
            },
            {
                label: "الحالة",
                field: "isFree",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
    },
    getters: {
        allBros(state) {
            return state.previousExamsList;
        },
    },
    mutations: {
        Get_Previous(state, payload) {
            state.previousExamsList = payload;
        },
        Get_Details_PreviousExam(state, payload) {
            state.DetailsPreviousExams = payload;
        },
        Add_Previous(state, payload) {
            state.previousExamsList.unshift(payload);
        },
        Reset_Dto(state) {
            console.log("in here");
            state.previousExamDto = {
                year: 0,
                name: "",
                isFree: true,
                subjectId: "",
                semesterId: "",
                teacherId: "",
            };
        },
        Get_PreviousExam_Names(state, payload) {
            state.previousExamsName = payload;
        },
    },
    actions: {
        async getPreviousExams({ commit }, payload) {
            console.log(payload, "dsffsdf");
            try {
                api.get(
                    `${
                        payload.subjectId
                            ? `PreviousExam/Dash/GetAll?SubjectId=${payload.subjectId}`
                            : `PreviousExam/Dash/GetAll`
                    }`,

                    ({ data }) => {
                        commit("Get_Previous", data);
                    },
                    {},
                    {
                        params: {
                            ...payload,
                            PreviousExamType: payload.PreviousExamType,
                        },
                    }
                );
            } catch (error) {
                console.error("Error fetching previous exams:", error);
                // Optionally handle error here
            }
        },

        getPreviousExamNames({ commit }) {
            api.get(
                `PreviousExam/App/GetAllNames`,
                ({ data }) => {
                    commit("Get_PreviousExam_Names", data);
                },
                () => {}
            );
        },
        async addPreviousExam({ commit }, payload) {
             api.post(
                "PreviousExam/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Previous", data);
                },
                {
                    success: "تم اضافة دورة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        getDetailsPreviousExams({ commit }, id) {
            api.get(`PreviousExam/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_PreviousExam", data);
            });
        },
        deleteUnit({ commit }, ids) {
            api.delete(
                `PreviousExam/Dash/Delete `,
                () => {
                    commit("delete", ids);
                    this.$router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف",
                    success: "تم الحذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
