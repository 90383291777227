import { Admin, SuperAdmin, Teacher, DataEntry, Accountant } from "@/router";
export default [
    {
        path: "/",
        redirect: "/admin/home",
    },
    {
        path: "/admin/home",
        name: "home",
        component: () => import("@admin/home/Home.vue"),
        meta: () => ({
            pageTitle: "الرئيسية",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: "الرئيسية",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/university",
        name: "university",
        components: {
            default: () => import("@admin/university/University.vue"),
            actions: () =>
                import("@admin/university/components/AddUniversity.vue"),
        },
        meta: () => ({
            pageTitle: "مراحل التعليم",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "مراحل التعليم",
                },
            ],
        }),
    },
    {
        path: "/admin/university/:id",
        name: "UniversityDetails",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/university/pages/UniversityDetails.vue"),
        },
        meta: () => ({
            pageTitle: "تفاصيل المرحلة",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "مراحل التعليم المدرسي",
                    to: "/admin/university",
                },
                {
                    text: " تفاصيل المرحلة ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/faculty",
        name: "faculty",
        components: {
            default: () => import("@admin/faculty/Faculty.vue"),
            actions: () => import("@admin/faculty/components/AddFaculty.vue"),
        },
        meta: () => ({
            pageTitle: "الكليات /الصفوف",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store-alt",
                    text: "مراحل التعليم ",
                },
            ],
        }),
    },
    {
        path: "/admin/faculty/:id",
        name: "FactulyDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/faculty/pages/FactulyDetails.vue"),
        },
        meta: () => ({
            pageTitle: "الكليات /الصفوف",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    text: " تفاصيل الصف ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/branches",
        name: "branches",
        components: {
            default: () => import("@admin/branches/Branches.vue"),
            actions: () => import("@admin/branches/components/AddBranch.vue"),
        },
        meta: () => ({
            pageTitle: "الفروع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store",
                    text: "الفروع",
                },
            ],
        }),
    },
    {
        path: "/admin/subjects",
        name: "subjects",
        components: {
            default: () => import("@admin/subjects/Subjects.vue"),
            actions: () =>
                import(
                    "@/app/admin/services/subjects/components/AddSubject.vue"
                ),
        },
        meta: () => ({
            pageTitle: "المواد",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                },
            ],
        }),
    },
    {
        path: "/admin/subjects/:id",
        name: "subjectDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/subjects/pages/SubjectDetails.vue"),
            actions: () =>
                import("@admin/subjects/components/toolBarSubject.vue"),
        },
        meta: () => ({
            pageTitle: "المواد",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "المواد",
                    to: "/admin/subjects",
                },
                {
                    text: " تفاصيل المادة ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/unit",
        name: "unit",
        components: {
            default: () => import("@admin/unit/Unit.vue"),
            actions: () => import("@admin/unit/components/AddUnit.vue"),
        },
        meta: () => ({
            pageTitle: "الوحدات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store",
                    text: "الوحدات",
                },
            ],
        }),
    },
    {
        path: "/admin/unit/:id",
        name: "DetailsUnit",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/unit/pages/DetailsUnit.vue"),
            actions: () => import("@admin/unit/components/toolBarUnit.vue"),
        },
        meta: () => ({
            pageTitle: "الوحدات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الوحدات",
                    to: "/admin/unit",
                },
                {
                    text: " تفاصيل الوحدة ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/chapter",
        name: "chapter",
        components: {
            default: () => import("@admin/chapter/Chapter.vue"),
            actions: () => import("@admin/chapter/components/AddChapter.vue"),
        },
        meta: () => ({
            pageTitle: "الاقسام الاساسية",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "store",
                    text: "الاقسام الاساسية",
                },
            ],
        }),
    },
    {
        path: "/admin/chapter/:id",
        name: "ChapterDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/chapter/pages/ChapterDetails.vue"),
            actions: () =>
                import("@admin/chapter/components/toolBarChapter.vue"),
        },
        meta: () => ({
            pageTitle: "الاقسام الاساسية",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الاقسام الاساسية",
                    to: "/admin/chapter",
                },
                {
                    text: " تفاصيل الاقسام الاساسية ",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/courses",
        name: "courses",
        components: {
            default: () => import("@admin/courses/Courses.vue"),
            actions: () =>
                import("@/app/admin/services/courses/components/AddCourse.vue"),
        },
        meta: () => ({
            pageTitle: "الكورس",
            roles: [SuperAdmin, Admin, DataEntry, Teacher],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الكورس",
                },
            ],
        }),
    },
    {
        path: "/admin/courses/:id",
        name: "courseDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/courses/pages/CourseDetails.vue"),
            actions: () =>
                import("@admin/courses/components/toolBarCourse.vue"),
        },
        meta: () => ({
            pageTitle: "الكورس",
            roles: [SuperAdmin, Admin, DataEntry, Teacher],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "books",
                    text: "الكورس",
                    to: "/admin/courses",
                },
                {
                    text: " تفاصيل الكورس ",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/lessons",
        name: "lessons",
        components: {
            default: () => import("@/app/admin/services/lessons/Lessons.vue"),
            actions: () => import("@admin/lessons/components/AddLesson.vue"),
        },
        meta: () => ({
            pageTitle: "الدروس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                },
            ],
        }),
    },
    {
        path: "/admin/lessons/:id",
        props: {
            default: true,
        },
        name: "lessonDetails",
        components: {
            default: () =>
                import("@/app/admin/services/lessons/pages/LessonDetails.vue"),
            actions: () =>
                import("@admin/lessons/components/toolBarLessons.vue"),
        },

        meta: () => ({
            pageTitle: "تفاصيل درس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الدروس",
                    to: "/admin/lessons",
                },
                {
                    text: "تفاصيل الدرس",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/sessions",
        name: "sessions",
        components: {
            default: () => import("@/app/admin/services/sessions/sessions.vue"),
            actions: () => import("@admin/sessions/components/AddSessions.vue"),
        },
        meta: () => ({
            pageTitle: "الجلسات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الجلسات",
                },
            ],
        }),
    },
    {
        path: "/admin/sessions/:id",
        props: {
            default: true,
        },
        name: "SessionsDetails",
        components: {
            default: () =>
                import(
                    "@/app/admin/services/sessions/pages/SessionsDetails.vue"
                ),
            actions: () =>
                import("@admin/sessions/components/toolBarSessions.vue"),
        },

        meta: () => ({
            pageTitle: "تفاصيل درس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-question-alt",
                    text: "الجلسات",
                    to: "/admin/sessions",
                },
                {
                    text: "تفاصيل الدرس",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/quiz",
        name: "quiz",
        components: {
            default: () => import("@/app/admin/services/quiz/Quiz.vue"),
            actions: () => import("@admin/quiz/components/AddQuiz.vue"),
        },
        meta: () => ({
            pageTitle: "كويزات الدروس",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "كويزات الدروس",
                },
            ],
        }),
    },

    {
        path: "/admin/quiz/:id",
        name: "quizDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/quiz/pages/QuizDetails.vue"),
            // actions: () => import("@admin/quiz/components/toolBarQuestion.vue"),
        },
        meta: () => ({
            pageTitle: " كويزات الدروس ",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "question-circle",
                    text: "  كويزات الدروس ",
                    to: "/admin/quiz",
                },
                {
                    text: "تفاصيل السؤال",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/courseQuestion",
        name: "courseQuestion",
        components: {
            default: () => import("@admin/courseQuestion/courseQuestion.vue"),
            actions: () =>
                import(
                    "@admin/courseQuestion/components/AddCourceQuestion.vue"
                ),
        },
        meta: () => ({
            pageTitle: "الاختبارات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-copy-alt",
                    text: "الاختبارات",
                },
            ],
        }),
    },
    {
        path: "/admin/courseQuestion/:id",
        name: "courseQuestionDetailse",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/courseQuestion/pages/courseQuestionDetailse.vue"
                ),
            actions: () =>
                import("@admin/courseQuestion/components/toolBarQuestion.vue"),
        },
        meta: () => ({
            pageTitle: " بنك الاسئلة ",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-copy-alt",
                    text: "  بنك الاسئلة ",
                    to: "/admin/courseQuestion",
                },
                {
                    text: "تفاصيل سؤال",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/courseQuestions",
        name: "courseQuestions",
        components: {
            default: () => import("@admin/courseQuestions/CourseQuestions.vue"),
            actions: () =>
                import(
                    "@admin/courseQuestions/components/AddCourseQuestions.vue"
                ),
        },
        meta: () => ({
            pageTitle: "بنك الاسئلة",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-plus",
                    text: "بنك الاسئلة",
                },
            ],
        }),
    },
    {
        path: "/admin/courseQuestions/:id",
        name: "courseQuestions",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import(
                    "@admin/courseQuestions/pages/CourseQuestionsDetails.vue"
                ),
            actions: () => import(
                "@admin/courseQuestions/components/toolBarBankQ.vue"
            ),
        },
        meta: () => ({
            pageTitle: "الدورات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "file-copy-alt",
                    text: "بنوك الاسئلة",
                    to: "/admin/courseQuestions",
                },
                {
                    text: "تفاصيل البنك",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/codes",
        name: "codes",
        components: {
            default: () => import("@admin/codes/Package.vue"),
            actions: () => import("@admin/codes/components/AddPackage.vue"),
        },
        meta: () => ({
            pageTitle: "رموز التفعيل",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "qrcode-scan",
                    text: "رموز التفعيل",
                },
            ],
        }),
    },
    {
        path: "/admin/codes/:id",
        name: "codesDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/codes/pages/PackageDetails.vue"),
            actions: () => import("@admin/codes/components/toolBarPackage.vue"),
        },
        meta: () => ({
            pageTitle: " رموز التفعيل",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "qrcode-scan",
                    text: " الحزم المتوفرة ",
                    to: "/admin/codes",
                },
                {
                    text: "تفاصيل الحزمة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint",
        name: "invoiceSellPoints",
        component: () => import("@admin/invoiceSellPoint/SellPoint.vue"),
        meta: () => ({
            pageTitle: "كشف حساب ",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint/:id",
        name: "invoiceSellPoint",
        props: {
            default: true,
            actions: true,
        },
        components: {
            default: () =>
                import("@admin/invoiceSellPoint/pages/SellPointDetails.vue"),
            actions: () =>
                import("@admin/invoiceSellPoint/pages/AddInvoiceSellPoint.vue"),
        },
        meta: (route) => ({
            pageTitle: " كشف الحساب",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoiceSellPoint",
                },
                {
                    text: route.query ? `فواتير ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/invoiceSellPoint/:id/:invoicid",
        name: "invoiceSellPointDetails",
        props: true,

        component: () =>
            import(
                "@admin/invoiceSellPoint/components/AddSellPointInvoice.vue"
            ),
        meta: (route) => ({
            pageTitle: "كشف حساب",
            roles: [SuperAdmin, Admin, Accountant],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "money-withdraw",
                    text: " كشف حساب نقاط البيع",
                    to: "/admin/invoiceSellPoint",
                },
                {
                    text:
                        route.params.invoicid && route.params.invoicid == 0
                            ? "فاتورة جديدة"
                            : "تفاصيل الفاتورة",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/students",
        name: "students",
        components: {
            default: () => import("@admin/students/Students.vue"),
            actions: () => import("@admin/students/components/AddStudent.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدم التطبيق",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                },
            ],
        }),
    },
    {
        path: "/admin/students/:id",
        name: "studentsDetails",
        props: {
            default: true,
        },
        components: {
            default: () => import("@admin/students/pages/StudentDetails.vue"),
            actions: () =>
                import("@admin/students/components/toolBarStudent.vue"),
        },
        meta: (route) => ({
            pageTitle: "مستخدم التطبيق",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "users-alt",
                    text: "مستخدم التطبيق",
                    to: "/admin/students",
                },
                {
                    text: route.query ? `تفاصيل ${route.query.name}` : "",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/dashboardUsers",
        name: "dashboardUsers",
        components: {
            default: () => import("@admin/dashboardUsers/DashboardUsers.vue"),
            actions: () =>
                import("@admin/dashboardUsers/components/AddDashboardUser.vue"),
        },
        meta: () => ({
            pageTitle: "مستخدمو اللوحة",
            roles: [SuperAdmin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "user-circle",
                    text: "مستخدمو اللوحة",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints",
        name: "sellPoints",
        components: {
            default: () => import("@admin/sellPoints/SellPoints.vue"),
            actions: () =>
                import("@admin/sellPoints/components/AddSellPoints.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقاط البيع",
                },
            ],
        }),
    },
    {
        path: "/admin/sellPoints/:id",
        name: "sellPointsDetail",
        props: {
            default: true,
        },
        components: {
            default: () =>
                import("@admin/sellPoints/pages/SellPointDetail.vue"),
            actions: () =>
                import("@admin/sellPoints/components/toolBarSellpoint.vue"),
        },
        meta: () => ({
            pageTitle: "نقاط البيع",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "university",
                    text: "نقطة البيع",
                    to: "/admin/sellpoints",
                },
                {
                    text: "تفاصيل",
                    active: true,
                },
            ],
        }),
    },

    {
        path: "/admin/settings",
        name: "settings",
        components: {
            default: () => import("@admin/settings/Settings.vue"),
        },
        meta: () => ({
            pageTitle: "الإعدادات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bookmark",
                    text: "الإعدادات",
                },
            ],
        }),
    },
    {
        path: "/admin/notifications",
        name: "notifications",
        components: {
            default: () => import("@admin/notifications/Notifications.vue"),
            actions: () =>
                import("@admin/notifications/components/AddNotification.vue"),
        },
        meta: () => ({
            pageTitle: "الإشعارات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "bell",
                    text: "الإشعارات",
                    active: false,
                },
            ],
        }),
    },

    {
        path: "/admin/contactUs",
        name: "contactUs",
        component: () => import("@admin/contactUs/ContactUs.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [SuperAdmin, Admin],
            layout: "vertical",

            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/contactUs/:id",
        name: "contactUsDetails",
        props: true,
        component: () => import("@admin/contactUs/pages/ContactUsDetails.vue"),
        meta: () => ({
            pageTitle: "بريد الشركة",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "envelope-download",
                    text: "بريد الشركة",
                    to: "/admin/contactUs",
                    active: false,
                },
                {
                    text: "  تفاصيل البريد",
                    active: true,
                },
            ],
        }),
    },
    {
        path: "/admin/adverts",
        name: "adverts",
        components: {
            default: () => import("@admin/adverts/Adverts.vue"),
            actions: () => import("@admin/adverts/components/AddAdvert.vue"),
        },
        meta: () => ({
            pageTitle: " الإعلانات",
            roles: [SuperAdmin, Admin],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "megaphone",
                    text: " الإعلانات",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/previousExams",
        name: "previousExams",
        components: {
            default: () => import("@admin/previousExams/PreviousExams.vue"),
            actions: () =>
                import("@admin/previousExams/components/AddPreviousExam.vue"),
        },
        meta: () => ({
            pageTitle: " الدورات",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "moneybag",
                    text: " الدورات",
                    active: false,
                },
            ],
        }),
    },
    {
        path: "/admin/previousExams/:id",
        name: "DetailsPreviousExams",
        components: {
            default: () =>
                import("@admin/previousExams/pages/DetailsPreviousExams.vue"),
                actions: () => import("@admin/previousExams/components/toolBarPreviousExam.vue"),
            },
        meta: () => ({
            pageTitle: "تفاصيل دورة",
            roles: [SuperAdmin, Admin, DataEntry],
            layout: "vertical",
            breadcrumb: [
                {
                    icon: "moneybag",
                    text: " تفاصيل دورة",
                    active: false,
                },
            ],
        }),
    },
];
