// import { getSearchResultOnRow } from "@Ekcore/util";
export default {
    state: {
        filterDto: {},
        searchDto: {
            keys: [],
            query: ""
        },
        tempFiles: []
    },
    getters: {
        // customersList(state, getter, glState) {
        //     return glState.customers.customersList.filter(serv => {
        //         return getSearchResultOnRow(state, serv);
        //     });
        // },
     
    },
    mutations: {
        Set_filter_Dto(state, payload) {
            Object.keys(payload).forEach(key => {
                state.filterDto[key] = payload[key];
            });
        },
        Set_Search_Dto(state, payload) {
            Object.assign(state.searchDto, payload);
        },
        Reset_Search_Dto(state) {
            Object.assign(state.searchDto, {
                keys: [],
                query: ""
            });
        },
        Reset_filter_Dto(state) {
            Object.assign(state.filterDto, {});
        },
        Update_Dto_Files(state, payload) {
            state.tempFiles = {...payload.dto, files: payload.imagesList}
        },
        Reset_Temp_Files(state) {
            state.tempFiles = [];
        }
    }
};
