import { getSearchResultOnRow } from "@Ekcore/util";
import api from "@api";

export default {
    state: {
        universityList: [],
        universityDetailsList: [
            {
                id: "",
                name: "",
                facultiesCount: 0,
                studentsCount: 0,
                dateCreated: "",
                faculties: [],
            },
        ],
        universityName: [],
        universityNameSub: [],
        universityNameCourse: [],
        isDialogOpen: false,
        imageUrl: "",
        universityDto: {
            name: "",
            imageUrl: "",
            type: 0,
        },
        columns: [
            {
                label: " اسم الجامعة ",
                field: "name",
            },
            {
                label: "  عدد الكليات ",
                type: "number",
                field: "facultiesCount",
            },
            {
                label: "عدد الطلاب المسجلين ",
                type: "number",
                field: "studentsCount",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
        newColumns: [
            {
                label: " اسم المرحلة ",
                field: "name",
            },
            {
                label: "  مرحلة التعليم ",
                type: "type",
                field: "type",
            },
            {
                label: "عدد الطلاب المسجلين",
                type: "studentsCount",
                field: "studentsCount",
            },
            {
                label: "عدد الصفوف ",
                type: "facultiesCount",
                field: "facultiesCount",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
        classesColumn: [
            {
                label: " اسم الصف ",
                field: "name",
            },
            {
                label: "  عدد المواد ",
                type: "subjectCount",
                field: "subjectCount",
            },
            {
                label: "عدد الطلاب المسجلين",
                type: "studentCount",
                field: "studentCount",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
    },
    getters: {
        allUinversities(state) {
            return state.universityName;
        },
        universityNameSub(state) {
            return state.universityNameSub;
        },
        universities({ universityList }, getter, { filter }) {
            return universityList.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
        getImageUrl(state) {
            return state.imageUrl;
        },
        universitiesList: (state) => state.universityName,
    },
    mutations: {
        Get_University_List(state, payload) {
            state.universityList = payload;
        },
        Add_University(state, payload) {
            state.universityList.unshift(payload);
        },
        Get_Details_University(state, payload) {
            state.universityDetailsList = { ...payload };
        },
        Update_University(state, payload) {
            let dto = state.universityList.find((ele) => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Get_Names_University(state, payload) {
            state.universityName = payload;
        },
        Get_Names_Courses(state, payload) {
            state.universityNameCourse = payload;
        },
        Get_Names_Sub_University(state, payload) {
            state.universityNameSub = payload;
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_UNIVERSITY_DTO(state, payload) {
            if (payload) state.universityDto = { ...payload };
            else
                state.universityDto = {
                    name: "",
                    imageUrl: "",
                };
        },
        Delete_Universities(state, payload) {
            state.universityList = state.universityList.filter(
                (el) => !payload.includes(el.id)
            );
        },
        Add_File(state, payload) {
            state.imageUrl = payload;
        },
    },
    actions: {
        getUniversityList({ commit }, StudentType) {
            api.get(
                `University/Dash/GetAll?StudentType=${
                    StudentType == undefined ? 0 : StudentType
                }`,
                ({ data }) => {
                    commit("Get_University_List", data);
                }
            );
        },
        getNamesUniversity({ commit }, type) {
            console.log("type", type);
            api.get(
                `University/Dash/GetNames?StudentType=${type ? type : 0}`,
                ({ data }) => {
                    commit("Get_Names_University", data);
                }
            );
        },
        getUniversityDetails({ commit }, id) {
            api.get(
                `University/Dash/GetUniversityById?id=${id}`,
                ({ data }) => {
                    commit("Get_Details_University", data);
                }
            );
        },
        getNamesCourses({ commit }, StudentType) {
            api.get(
                `University/Dash/GetNamesWithCourses?StudentType=${
                    StudentType == undefined ? 0 : StudentType
                }`,
                ({ data }) => {
                    commit("Get_Names_Courses", data);
                }
            );
        },
        getNamesSubUniversity({ commit }) {
            api.get("University/Dash/GetNamesWithSubject", ({ data }) => {
                commit("Get_Names_Sub_University", data);
            });
        },
        addUniversity({ commit, dispatch }, payload) {
            api.post(
                "University/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_University", data);
                    dispatch("getNamesUniversity");
                },
                {
                    success: "تم اضافة الجامعة بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        updateUniversity({ commit }, payload) {
            api.post(
                "University/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_University", data);
                    commit("SET_UNIVERSITY_DTO");
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الجامعة؟",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        deleteUniversities({ commit }, ids) {
            api.delete(
                `University/Dash/Delete`,
                () => {
                    commit("Delete_Universities", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الجامعات المحددة",
                    success: "تم حذف الجامعات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
