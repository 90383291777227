import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        facultyName: [],
        facultyList: [],
        isDialogOpen: false,
        facultyDto: {
            name: "",
            universityId: "",
            yearCount: 0,
            type: 0,
        },
        columns: [
            {
                label: " اسم الكلية ",
                field: "name",
            },
            {
                label: "  تابعة ل ",
                field: "universityId",
            },
            {
                label: "  عدد الفروع ",
                type: "number",
                field: "departmentsCount",
            },
            {
                label: "عدد الطلاب المسجلين ",
                type: "number",
                field: "studentsCount",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
        newColumns: [
            {
                label: " اسم الصف ",
                field: "name",
            },
            {
                label: "عدد المواد ",
                type: "subjectCount",
                field: "subjectCount",
            },
            {
                label: "  اسم المرحلة ",
                type: "universityName",
                field: "universityName",
            },
            {
                label: "عدد الطلاب المسجلين",
                type: "studentsCount",
                field: "studentsCount",
            },

            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
        classesColumn: [
            {
                label: " اسم المادة ",
                field: "name",
            },
            {
                label: "  الفصل ",
                type: "semesterName",
                field: "semesterName",
            },
            {
                label: "  عدد الوحدات ",
                type: "unitCount",
                field: "unitCount",
            },
            {
                label: "عدد الطلاب المسجلين",
                type: "studentCount",
                field: "studentCount",
            },
            {
                label: "تاريخ الإضافة",
                sortable: true,
                field: "dateCreated",
            },
            {
                label: "التفاصيل",
                field: "id",
                sortable: false,
            },
        ],
        detailsFaculty: {
            id: "",
            name: "",
            subjectsCount: 0,
            studentsCount: 0,
            dateCreated: "",
            subjects: [
                {
                    id: "",
                    name: "",
                    semesterName: "",
                    unitCount: 0,
                    studentCount: 0,
                    dateCreated: "",
                },
            ],
        },
        tab: 0,
    },
    getters: {
        faculties({ facultyList }, getter, { filter }) {
            return facultyList.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
        facultiesList: (state) => state.facultyName,
    },
    mutations: {
        Get_Faculty_List(state, payload) {
            state.facultyList = payload;
        },
        Add_Faculty(state, payload) {
            state.facultyList.unshift(payload);
        },
        Update_Faculty(state, payload) {
            let dto = state.facultyList.find((ele) => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Set_Faculty_Name(state, payload) {
            state.facultyName = payload;
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        Get_Details_Faculty(state, payload) {
            state.detailsFaculty = { ...payload };
        },
        SET_FACULTY_DTO(state, payload) {
            if (payload) state.facultyDto = { ...payload };
            else
                state.facultyDto = {
                    name: "",
                    universityId: "",
                    yearCount: 0,
                };
        },
        Delete_Faculties(state, payload) {
            state.facultyList = state.facultyList.filter(
                (el) => !payload.includes(el.id)
            );
        },
    },
    actions: {
        getFacultyList({ commit }, tab) {
            api.get(
                `Faculty/Dash/GetAll?StudentType=${tab ? tab : 0}`,
                ({ data }) => {
                    commit("Get_Faculty_List", data);
                }
            );
        },
        addFaculty({ commit, dispatch }, payload) {
            api.post(
                "Faculty/Dash/Add",
                payload,
                ({ data }) => {
                    commit("Add_Faculty", data);
                    commit("SET_FACULTY_DTO");
                    dispatch("getNamesUniversity");
                },
                {
                    success: "تم اضافة الكلية بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        updateFaculty({ commit, dispatch }, payload) {
            api.post(
                "Faculty/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Faculty", data);
                    commit("SET_FACULTY_DTO");
                    dispatch("getNamesUniversity");
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الكلية؟",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        getFacultyDetails({ commit }, id) {
            api.get(`Faculty/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Faculty", data);
            });
        },
        getFacultyName({ commit }, type) {
            api.get(
                `Faculty/Dash/GetNames${type != null || undefined ? '?studentType=' +type : ''}`,
                ({ data }) => {
                    commit("Set_Faculty_Name", data);
                }
            );
        },
        deleteFaculties({ commit }, ids) {
            api.delete(
                `Faculty/Dash/Delete`,
                () => {
                    commit("Delete_Faculties", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الكليات المحددة",
                    success: "تم حذف الحسابات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
