import Vue from "vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import Unicon from "vue-unicons";
import EKDialog from "@Ekcore/components/EK-dialog";
import EKModal from "@Ekcore/components/EK-modal";
import activaitor from "@Ekcore/components/EK-dialog/activaitor";
import EKPagination from "@Ekcore/components/EK-pagination";
import EKTable from "@Ekcore/components/EK-table";
import EKTableCollapse from "@Ekcore/components/EK-table-collapse";
import EKTree from "@Ekcore/components/EK-tree/tree.js";
import EKInputImage from "@Ekcore/components/EK-forms/EK-input-image";
import EKInputText from "@Ekcore/components/EK-forms/EK-input-text";
import EKInputSelect from "@Ekcore/components/EK-forms/EK-input-select";
import EKInputPicker from "@Ekcore/components/EK-forms/EK-input-picker";
import EKDatePicker from "@Ekcore/components/EK-forms/EK-date-picker";
import EKInputTextarea from "@Ekcore/components/EK-forms/EK-input-textarea";
import EKImagePicker from "@Ekcore/components/EK-forms/EK-image-picker";
import EKInputPhone from "@Ekcore/components/EK-forms/EK-input-phone";
import { ValidationObserver, ValidationProvider } from "vee-validate";
Vue.use(Unicon);
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.component("ek-dialog", EKDialog);
Vue.component("ek-activator", activaitor);
Vue.component("ek-pagination", EKPagination);
Vue.component("ek-table", EKTable);
Vue.component("ek-table-collapse", EKTableCollapse);
Vue.component("ek-tree", EKTree);
Vue.component("ek-date-picker", EKDatePicker);
Vue.component("ek-input-image", EKInputImage);
Vue.component("ek-input-text", EKInputText);
Vue.component("ek-input-select", EKInputSelect);
Vue.component("ek-input-picker", EKInputPicker);
Vue.component("ek-input-textarea", EKInputTextarea);
Vue.component("ek-input-phone", EKInputPhone);
Vue.component("ek-image-picker", EKImagePicker);
Vue.component("ek-modal", EKModal);
Vue.component("ek-date-picker", EKDatePicker);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
import {
    uniBuilding,
    uniTrash,
    uniText,
    uniMoon,
    uniCircle,
    uniSun,
    uniPlus,
    uniHomeAlt,
    uniTrashAlt,
    uniCalendarAlt,
    uniUser,
    uniDesktop,
    uniHorizontalAlignRight,
    uniMap,
    uniNewspaper,
    uniServerAlt,
    uniLayerGroup,
    uniSearch,
    uniTimes,
    uniFileBlank,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleLeft,
    uniAngleUp,
    uniEllipsisV,
    uniSync,
    uniAngleDown,
    uniPen,
    uniArrowLeft,
    uniFavorite,
    uniShoppingBag,
    uniSwatchbook,
    uniImageRedo,
    uniImagePlus,
    uniUsersAlt,
    uniBooks,
    uniUniversity,
    uniFileQuestionAlt,
    uniQuestionCircle,
    uniMegaphone,
    uniBell,
    uniBookmark,
    uniBill,
    uniEnvelope,
    uniEnvelopeQuestion,
    uniUserCircle,
    uniBookOpen,
    uniMoneybag,
    uniEnvelopeDownload,
    uniWallet,
    uniVectorSquare,
    uniMicrophone,
    uniMoneyWithdraw,
    uniEditAlt,
    uniChartLine,
    uniTable,
    uniCheckCircle,
    uniArrowRandom,
    uniEyeSlash,
    uniCheck,
    uniVideo,
    uniEye,
    uniMinus,
    uniStoreAlt,
    uniStore,
    uniQrcodeScan,
    uniFilePlus,
    uniFileCopyAlt,
} from "vue-unicons/src/icons";

Unicon.add([
    // for theme
    uniStoreAlt,
    uniFilePlus,
    uniEnvelope,
    uniVideo,
    uniMoneyWithdraw,
    uniChartLine,
    uniArrowRandom,
    uniCheckCircle,
    uniTable,
    uniEllipsisV,
    uniWallet,
    uniEnvelopeDownload,
    uniUniversity,
    uniEnvelopeQuestion,
    uniBookmark,
    uniBill,
    uniBell,
    uniText,
    uniMegaphone,
    uniQuestionCircle,
    uniFileQuestionAlt,
    uniBookmark,
    uniFileCopyAlt,
    uniMoneybag,
    uniBookOpen,
    uniUserCircle,
    uniUniversity,
    uniBooks,
    uniUsersAlt,
    uniImagePlus,
    uniImageRedo,
    uniBuilding,
    uniTrash,
    uniPlus,
    uniLayerGroup,
    uniServerAlt,
    uniDesktop,
    uniNewspaper,
    uniMap,
    uniSun,
    uniHorizontalAlignRight,
    uniMoon,
    uniCircle,
    uniHomeAlt,
    uniFileBlank,
    uniCalendarAlt,
    uniSearch,
    uniUser,
    uniTrashAlt,
    uniTimes,
    uniFolderOpen,
    uniFolder,
    uniAngleRight,
    uniAngleUp,
    uniAngleLeft,
    uniAngleDown,
    uniSync,
    uniArrowLeft,
    uniPen,
    uniFavorite,
    uniShoppingBag,
    uniSwatchbook,
    uniVectorSquare,
    uniMicrophone,
    uniEditAlt,
    uniEyeSlash,
    uniCheck,
    uniEye,
    uniMinus,
    uniStore,
    uniQrcodeScan,

    // end,
]);
