import api from "@api";
import router from "@/router";

export default {
    state: {
        filterVal: "",
        helloSub: "hello sub",
        subjects: [],
        subjectsNames: [],
        url: "",
        filterDto: {
            semesterId: "",
            yearId: "",
            facultyId: "",
            universityId: "",
            search: "",
        },

        subjectDto: {
            name: "",
            url: "",
            description: "",
            price: 0,
            mark: 100,
            semesterId: "",
            yearId: "",
            departmentId: "",
            universityId: "",
            facultyId: "",
        },

        subjectDetailDto: {
            id: "",
            name: "",
            url: "",
            description: "",
            price: 0,
            semesterId: "",
            yearId: "",
            universityId: "",
            facultyId: "",
            departmentId: "",
            subscribersCount: 0,
            courses: [],
        },
    },

    mutations: {
        Get_Subjects_List(state, payload) {
            console.log(payload, "eeeeeeeeeeeee");
            state.subjects = payload;
        },
        Get_Sub_Names(state, payload) {
            state.subjectsNames = payload;
        },
        Set_Sub(state, payload) {
            state.subjects = payload;
        },
        Add_Subjects(state, payload) {
            state.subjects.subjects.push(payload);
        },
        Add_Courses_Subjects(state, payload) {
            state.subjectDetailDto.courses.push(payload);
        },

        Get_Details_Subject(state, payload) {
            state.subjectDetailDto = { ...payload };
        },
        Update_Subject(state, payload) {
            state.subjectDetailDto = { ...payload };
        },
        Add_File(state, payload) {
            state.subjectDetailDto.url = payload;
             
        },
        Filter_Course(state, payload) {
            console.log("dfdsfdsf", payload);
            state.filterVal = payload;
        },
        Reset_Subject(state) {
            state.subjectDto = {
                name: "",
                url: "",
                description: "",
                price: 0,
                semesterId: "",
                yearId: "",
                departmentId: "",
                universityId: "",
                facultyId: "",
            };
        },
        Delete_Subject(state, id) {
            state.subjects.splice(
                state.subjects.findIndex((item) => item.id == id),
                1
            );
        },
    },
    getters: {
        getUrl(state) {
            return state.url;
        },
        getSubjectsNamesGetter(state) {
            return state.subjectsNames;
        },
        filterCourses(state) {
            if (state.filterVal) {
                console.log("ddddddddd", state.filterVal);
                return state.subjectDetailDto.units.filter((ele) => {
                    return (
                        ele.name
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.chapterCount
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase()) ||
                        ele.studentCount
                            .toString()
                            .toLowerCase()
                            .includes(state.filterVal.toLowerCase())
                    );
                });
            } else return state.subjectDetailDto.units;
        },
    },

    actions: {
        getSubjectsList({ commit }, payload) {
            api.get(
                `Subject/Dash/GetAll?StudentType=${
                    payload.studentType != null || undefined
                        ? payload.studentType
                        : 0
                }`,
                ({ data }) => {
                    commit("Get_Subjects_List", data);
                },
                {},
                {
                    params: { ...payload },
                }
            );
        },
        getSubjects({ commit }, StudentType) {
            api.get(
                `Subject/Dash/GetAll?StudentType=${
                    StudentType != null || undefined ? StudentType : 0
                }&PageIndex=1&PageSize=10`,
                ({ data }) => {
                    commit("Set_Sub", data);
                }
            );
        },
        getSubjectsNames({ commit }, StudentType) {
            console.log("fsdfdf", StudentType);
            api.get(
                `Subject/Dash/GetAllNames${
                    StudentType != null || undefined ? '?StudentType=' + StudentType : ''
                }`,
                ({ data }) => {
                    commit("Get_Sub_Names", data);
                }
            );
        },
        addFileSubject({ commit }, payload) {
            console.log("addFile");
            var once = true;
            if(once){
once = false;
return new Promise((resolve, reject) => {
    console.log("addFilePromise");
    api.form.post(
        "File/Upload",
        payload,
        (res) => {
            commit("Add_File", res.data);
            resolve(res);
        },
        {
            success: "تم إضافة الملف بنجاح",
            error: "فشل إضافة الملف",
        },
        (er) => {
            reject(er);
        }
    );
});
            }
           
        },
        addSubject({ commit }, payload) {
            console.log(payload);
            return new Promise((resolve, reject) => {
                api.post(
                    "Subject/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Subjects", res.data);
                        // commit("Set_Subjects_Dto");
                        resolve(res.data);
                    },
                    {
                        success: "تم إضافة المادة بنجاح",
                        error: "فشل إضافة المادة",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },

        getSubjectDetails({ commit }, id) {
            api.get(`Subject/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_Details_Subject", data);
            });
        },
        updateSubject({ commit }, payload) {
            api.post(
                "Subject/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Subject", data);
                },
                {
                    success: "تم تعديل المادة  بنجاح",
                    error: "فشل تعديل المادة ",
                }
            );
        },

        deleteSubject({ commit }, ids) {
            api.delete(
                `Subject/Dash/Delete `,
                () => {
                    commit("deleteSubjects", ids);
                    router.go(-1);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف  المادة",
                    success: "تم حذف المواد بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
