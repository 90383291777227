export const uploader = {
    mounted(el, dir) {

        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.style.display = "none";
        document.body.appendChild(fileInput);
        el.addEventListener("click", () => {
            fileInput.click();
        });
        fileInput.addEventListener("change", (event) => {
            const file = event.target.files[0];
            toBase64(file).then((base64) => {
                dir.value({
                    file,
                    base64,
                });
            });
        });
        // this will be called for both `mounted` and `updated`
        const toBase64 = (file) => {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.onerror = (error) => reject(error);
            });
        };
    },
};
