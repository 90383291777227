<template>
  <div class="vue-file-component w-100">
    <label
      v-if="label"
      class="mr-1 mb-1 col-form-label m-0 w-100"
      style="margin: 0 !important"
      >{{ label }}</label
    >
    <FormulateForm name="imagePicker" ref="imagePicker">
      <FormulateInput
        id="vfile"
        ref="formulate"
        accept=".jpg, .jpeg ,.png ,.svg ,icon ,.webP ,.gif ,.tiff ,.tif"
        @input="handleInput"
        @file-removed="deleteFile"
        @file-upload-complete="addFile"
        :add-label="addLabel"
        type="image"
        class="formulate"
        name="file"
        v-bind="$attrs"
      >
      </FormulateInput>
    </FormulateForm>

    <div class="image-previewer w-100" v-if="showPreview">
      <b-row v-if="isMulti" cols="2" cols-md="3">
        <b-col v-for="(image, i) in images" class="my-1" :key="i">
          <div class="img">
            <b-button
              class="position-absolute rounded-pill p-0 delete-img-btn"
              :variant="image.deleted ? 'success' : 'danger'"
              @click="deleteImage(image.id)"
            >
              <unicon
                fill="#fff"
                height="15"
                :name="image.deleted ? 'image-redo' : 'times'"
              ></unicon>
            </b-button>
            <b-img
              class="w-100 rounded-15"
              style="height: 100px; object-fit: cover"
              :class="[{ deleted: image.deleted }, 'image-preview']"
              :src="$store.getters['app/domainHost'] + '/' + image"
            ></b-img>
          </div>
        </b-col>
      </b-row>

      <div v-else class="position-relative">
        <div v-if="image">
          <b-button
            class="position-absolute rounded-pill single-delete-btn d-flex justify-content-center align-items-center"
            variant="danger"
            @click="deleteImage"
            v-if="!noDeleteBtn"
          >
            <unicon fill="#fff" :name="image.deleted ? 'trash-alt' : 'trash-alt'" />
          </b-button>
          <b-img
            :class="{ coverd: cover }"
            class="border single-img w-100 rounded-20"
            :src="$store.getters['app/domainHost'] + '/' + image"
          ></b-img>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Compressor from "compressorjs";

export default {
  props: {
    quality: { type: Number, default: 0.6 },
    label: String,
    value: null,
    noDeleteBtn: Boolean,
    cover: Boolean,
    addLabel: { type: String, default: "إضافة صور جديدة" },
  },
  data() {
    return {
      images: [],
      files: [],
      image: null,
      file: null,
    };
  },

  computed: {
    isMulti() {
      return this.$attrs.multiple == "";
    },
    showPreview() {
      return !!this.image || this.images.length;
    },
  },

  created() {
    if (this.isMulti) {
      this.value.forEach((element) => {
        this.images.push({
          ...element,
          deleted: false,
        });
      });
    } else {
      this.image = this.value;
    }
  },

  methods: {
    handleInput(event) {
      let vm = this;
      if (this.isMulti) {
        event.fileList.forEach((file) => {
          this.files.push(file);
        });
        this.upload();
      } else if (!this.$attrs.multiple) {
        this.image = null;

        new Compressor(event.files[0].file, {
          quality: vm.quality,
          success(res) {
            vm.$emit(
              "input",
              new File([res], "newFile.jpg", {
                lastModified: new Date().getTime(),
                type: res.type,
              })
            );
          },
          error(er) {
            console.log("er :>> ", er);
          },
        });
      }
    },
    deleteImage(id) {
      if (this.isMulti) {
        this.images[this.images.findIndex((el) => el.id == id)].deleted = !this.images[
          this.images.findIndex((el) => el.id == id)
        ].deleted;
      } else {
        this.$emit("imageDeleted");
        this.image = null;
      }
      this.upload();
    },
    deleteFile(e) {
      if (e.length) this.files = e.map((ob) => ob.file);
      else this.files = [];
      this.upload();
    },
    addFile({ file }) {
      if (this.isMulti) {
        if (!this.files.some((el) => el.name == file.name)) {
          this.files.push(file);
          this.upload();
        }
      }
    },

    async upload() {
      let vm = this;
      if (this.isMulti) {
        if (!this.files.length) {
          this.$emit("input", {
            documents: this.images,
            files: [],
          });
          return;
        }

        let compressedFiles = [];
        await this.files.forEach((file) => {
          new Compressor(file, {
            quality: vm.quality,
            success(res) {
              compressedFiles.push(res);
              let oldImages = vm.images
                .filter((el) => !el.deleted)
                .map((el) => {
                  let doc = { ...el };
                  delete doc.deleted;
                  return doc;
                });

              vm.$emit("input", {
                documents: oldImages,
                files: compressedFiles,
              });
            },
            error(er) {
              console.log("er :>> ", er);
            },
          });
        });
      } else {
        if (this.file) {
          new Compressor(this.file, {
            quality: vm.quality,
            success(res) {
              vm.$emit(
                "input",
                new File([res], res.name, {
                  lastModified: new Date().getTime(),
                  type: res.type,
                })
              );
            },
            error(er) {
              console.log("er :>> ", er);
            },
          });
        }
      }
    },
    reset() {
      // this.$formulate.reset("imagePicker");
      this.file = null;
      this.files = [];
    },
  },

  watch: {
    value(newVal) {
      if (this.isMulti) {
        newVal.forEach((element) => {
          this.images.push({
            ...element,
            deleted: false,
          });
        });
      } else {
        if (typeof newVal == "string") this.image = newVal;
      }
      this.upload();
    },
  },
};
</script>

<style lang="scss">
.vue-file-component {
  [dir]
    .formulate-input[data-classification="file"]
    .formulate-input-upload-area
    input:focus
    ~ .formulate-input-upload-area-mask,
  [dir]
    .formulate-input[data-classification="file"]
    .formulate-input-upload-area
    input:hover
    ~ .formulate-input-upload-area-mask,
  [dir]
    .formulate-input[data-classification="file"]
    .formulate-input-upload-area
    input[data-is-drag-hover]
    ~ .formulate-input-upload-area-mask {
    border-color: #7a7a7a !important;
  }

  * {
    border-color: #7a7a7a;
    &:hover {
      .formulate-input-upload-area-mask {
        border-color: #7a7a7a !important ;
        &::before {
          background-color: #7a7a7a !important;
        }
      }
    }
  }
  max-width: 100%;

  .formulate-input-element {
    max-width: 100% !important;
  }

  .formulate-input {
    &:active,
    &:focus {
      .formulate-input-upload-area-mask {
        border-color: #7a7a7a !important ;
      }
    }
    &:hover {
      .formulate-input-upload-area-mask {
        border-color: #7a7a7a !important ;
        &::before {
          background-color: #7a7a7a !important;
        }
      }
    }
    .formulate-input-upload-area-mask {
      border-radius: 10px !important;
    }
    .formulate-file-image-preview {
      img {
        object-fit: cover !important;
      }
    }
    .formulate-file-remove {
      position: absolute !important;
      right: 25px !important;
    }
    .formulate-file {
      width: 100%;
      position: relative;
      border-radius: 10px !important;
    }
  }
  img {
    border-radius: 5px !important;
  }
  .single-img {
    background-color: #eee;
    object-fit: contain;
    max-height: 300px;
  }
}
.deleted {
  opacity: 0.5;
  filter: grayscale(100%);
}

.delete-img-btn {
  z-index: 2;
  top: 10px;
  right: 20px;
  height: 30px;
  width: 30px;
  transition: 0.5s;
}

.coverd {
  object-fit: cover !important;
}
.single-delete-btn {
  top: 10px;
  right: 10px;
  height: 35px;
  width: 35px;
}
</style>
