import api from "@api";
import { getSearchResultOnRow } from "@Ekcore/util";
import router from "@/router";
import { serialize } from "object-to-formdata";

export default {
    state: {
        uploadFile: [],
        CourseQuestion: [],
        prevExamsName: [],
        previousExam: [],
        isDialogOpen: false,
        courseQDto: {
            text: null,
            group: null,
            order: 0,
            fileUrl: null,
            fileType: null,
            previousExamId: null,
            hint: null,
            answers: [],
            tags: [],
        },
        columns: [
            {
                label: " العنوان ",
                field: "text",
            },
            {
                label: " الصورة ",
                field: "fileUrl",
            },
            {
                label: " التلميح ",
                field: "hint",
            },
            {
                label: "  صورة التتلميح ",
                field: "hintFileUrl",
            },
            {
                label: "tags ",
                field: "tags",
            },
            {
                label: "التفاصيل",
                field: "details",
                sortable: false,
            },
        ],
    },
    getters: {
        CourseQuestionsList({ CourseQuestion }, getters, { filter }) {
            return CourseQuestion.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
    },
    mutations: {
        Get_CourseQuestions_List(state, payload) {
            state.CourseQuestion = payload;
        },
        Get_previousExam_List(state, payload) {
            state.previousExam = payload;
        },
        Get_CourseQuestions_Details(state, payload) {
            console.log("payload", payload);
            state.courseQDto = { ...payload };
        },
        Update_Course_Question(state, payload) {
            state.courseQDto = {...payload}
            // console.log(state.courseQDto.answers , "payload");
            
            // let dto = state.CourseQuestion.find((ele) => ele.id == payload.id);
            // console.log( Object.assign(dto, payload), "dto");
            // Object.assign(dto, payload);

        },

        Add_Course_Question(state, payload) {
            state.CourseQuestion.unshift(payload);
        },
        SET_Course_Question_DTO(state, payload) {
            if (payload) state.courseQDto = { ...payload };
            else
                state.courseQDto = {
                    text: "",
                    group: null,
                    fileUrl: "",
                    previousExamId: "",
                    hint: {
                        text: "",
                        fileUrl: "",
                    },
                    answers: [
                        {
                            text: "",
                            isCorrect: true,
                        },
                    ],
                    tags: [""],
                };
        },
        Reset_Dto(state) {
            Object.assign(state.courseQDto, {
                text: "",
                group: null,
                fileUrl: "",
                previousExamId: "",
                hint: {
                    text: "",
                    fileUrl: "",
                },
                answers: [
                    {
                        text: "",
                        isCorrect: true,
                    },
                ],
                tags: [""],
            });
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        Delete_Course_Question(state, ids) {
            state.CourseQuestion = state.CourseQuestion.filter(
                (el) => !ids.includes(el.id)
            );
        },
        Add_File_Url(state, payload) {
            state.courseQDto.fileUrl = payload;
        },
        Add_Hint_Url(state, payload) {
            state.courseQDto.hint.fileUrl = payload;
        },
        Add_Ansers_File(state, { payload, index }) {
            // Check if answers array exists and index is within bounds
            if (
                Array.isArray(state.courseQDto.answers) &&
                state.courseQDto.answers[index]
            ) {
                state.courseQDto.answers[index].fileUrl = payload;
            } else {
                console.error(
                    "Invalid index or answers array is not initialized"
                );
            }
        },
        Get_PreviousExam_Names(state, payload) {
            state.prevExamsName = payload;
        },
    },
    actions: {
        getACourseQuestionsList({ commit }, PreviousExamId) {
            api.get(
                PreviousExamId
                    ? `CourseQuestion/Dash/GetAll?PreviousExamId=${PreviousExamId}`
                    : `CourseQuestion/Dash/GetAll`,
                ({ data }) => {
                    commit("Get_CourseQuestions_List", data);
                }
            );
        },
        // getpreviousExamList({ commit }, query) {
        //     api.get(
        //         query
        //             ? `PreviousExam/App/GetAllNames?query=${query}`
        //             : `PreviousExam/App/GetAllNames`,
        //         ({ data }) => {
        //             commit("Get_previousExam_List", data);
        //         }
        //     );
        // },
        addFileUrl({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_File_Url", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addFileHint({ commit }, payload) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_Hint_Url", res.data);
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },
        addFileAnser({ commit }, { payload, index }) {
            return new Promise((resolve, reject) => {
                api.form.post(
                    "File/Upload",
                    payload,
                    (res) => {
                        commit("Add_Ansers_File", { fileUrl: res.data, index });
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الملف بنجاح",
                        error: "فشل إضافة الملف",
                    },
                    (er) => {
                        reject(er);
                    }
                );
            });
        },

        getCourseQuestionsDetails({ commit }, id) {
            api.get(`CourseQuestion/Dash/GetById?id=${id}`, ({ data }) => {
                commit("Get_CourseQuestions_Details", data);
            });
        },

        addCourseQuestions({ commit }, payload) {
            return new Promise((resolve) => {
                api.post(
                    "CourseQuestion/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Course_Question", res.data);
                        commit("SET_Course_Question_DTO");
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الاختبار  بنجاح",
                        error: "فشل إضافة الاختبار",
                    }
                );
            });
        },
        updateCourseQuestion({ commit }, payload) {
            if(payload.group == ""){
                payload.group = null;
            }
            api.post(
                "CourseQuestion/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Course_Question", data);
                    router.go(-1);
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الاختبار",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },
        updatePreviouseExam({ commit }, payload) {
            api.post(
                "PreviousExam/Dash/Modify",
                payload,
                ({ data }) => {
                    commit("Update_Course_Question", data);
                    router.go(-1);
                },
                {
                    confirm: "هل تريد بالتأكيد تعديل معلومات الاختبار",
                    success: "تم التعديل بنجاح  ",
                    error: "فشل التعديل.. يرجى إعادة المحاولة",
                }
            );
        },

        async  exportToExcel({ commit  }, { id, name }) {

            try {
                console.log(name);
               
                const response = await api.post(
                    "PreviousExam/App/ExportSchoolExcel?id=" + id,
                    {}, // Or use `undefined` if no data is needed in the body
                    (data) => {
                        console.log(data);
                
                        // Create a blob from the response data
                        const blob = new Blob([data.data], {
                            type: data.headers["content-type"], // Use `data.headers` instead of `response.headers`
                        });
                
                       // Extract the filename from Content-Disposition header
         let file_name = name; // Fallback filename

       
     

                        // Create a download link for the blob
                        const a = document.createElement("a");
                        a.href = window.URL.createObjectURL(blob);
                        a.download = file_name;
                        document.body.appendChild(a);
                        a.click();
                
                        // Remove the link after downloading
                        document.body.removeChild(a);
                    },
                    { success: "سيتم بدء التحميل الآن", error: "error" }, // Messages for success and error
                    undefined, // No custom error handling function
                    {
                        responseType: "blob", // Axios configuration for blob response
                    }
                );
                console.log(response);
         
              } catch (error) {
                console.error("Export error:", error);
              }
              commit("IS_DIALOG_OPEN", false);
         
            
            
        },
        // deleteCourseQuestion({ commit }, id) {
        //     api.delete(
        //         `CourseQuestion/Dash/Delete?id=${id}`,
        //         ({data}) => {
        //             commit("Delete_Course_Question", data);
        //             // router.go(-1);
        //         },
        //         {
        //             confirm: " ?هل تريد بالتأكيد الحذف   ",
        //             success: "تم حذف  بنجاح",
        //             error: "فشل الحذف ..يرجى اعادة المحاولة ",
        //         },
        //         id
        //     );
        // },
        deletePreviousExam({ commit }, id) {
            api.delete(
                `PreviousExam/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Course_Question", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف   ",
                    success: "تم حذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                []
            );
        },
        deletePreviousExams({ commit }, ids) {
            api.delete(
                `PreviousExam/Dash/Delete`,
                () => {
                    commit("Delete_Course_Question", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف   ",
                    success: "تم حذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
        deleteCourseQuestion({ commit }, id) {
            api.delete(
                `CourseQuestion/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Course_Question", id);
                },
                {
                    confirm: " ?هل تريد بالتأكيد الحذف   ",
                    success: "تم حذف  بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                []
            );
        },
        async AddFromSchoolExcel({ commit }, payload) {
            api.post(
                "PreviousExam/App/AddFromSchoolExcel",
                serialize(payload, {
                    indices: true,
                    dotsForObjectNotation: true,
                }),

                ({ data }) => {
                    commit("Add_Previous", data);
                },
                {
                    success: "تم الاضافة  بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        async AddFromExcel({ commit }, payload) {
            api.post(
                "PreviousExam/App/AddFromExcel",
                serialize(payload, {
                    indices: true,
                    dotsForObjectNotation: true,
                }),

                ({ data }) => {
                    commit("Add_Previous", data);
                },
                {
                    success: "تم الاضافة  بنجاح",
                    error: "فشلت الاضافة ..يرجى اعادة المحاولة ",
                }
            );
        },
        getPrevExamNames({ commit }) {
            api.get(`PreviousExam/App/GetAllNames`, ({ data }) => {
                commit("Get_PreviousExam_Names", data);
            });
        },
    },
};
