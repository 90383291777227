import settings from "@admin/settings";
import notifications from "@admin/notifications";
import contactUs from "@admin/contactUs";
import dashboardUsers from "@admin/dashboardUsers";
import adverts from "@admin/adverts";
import subjects from "@admin/subjects";
import lessons from "@admin/lessons";
import courses from "@admin/courses";
import students from "@admin/students";
import sellPoints from "@admin/sellPoints";
import university from "@admin/university";
import previousExams from "@admin/previousExams";
import faculty from "@admin/faculty";
import branches from "@admin/branches";
import codes from "@admin/codes";
import quiz from "@admin/quiz";
import home from "@admin/home";
import courseQuestion from "@admin/courseQuestion";
import CourseQuestions from "@admin/courseQuestions";
import invoiceSellPoint from "@admin/invoiceSellPoint";
import unit from "@admin/unit";
import sessions from "@admin/sessions";
import chapter from "@admin/chapter";
export default {
    codes,
    quiz,
    home,
    university,
    faculty,
    branches,
    settings,
    sellPoints,
    notifications,
    dashboardUsers,
    adverts,
    lessons,
    subjects,
    contactUs,
    students,
    courses,
    invoiceSellPoint,
    courseQuestion,
    CourseQuestions,
    previousExams,
    unit,
    chapter,
    sessions,
};
