import api from "@api";
import router from "@/router";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        uploadFile: [],
        adverts: [],
        isDialogOpen: false,
        advertDto: {
            title: "",
            priority: "",
            startDate: "",
            endDate: "",
            url: "",
        },
    },
    getters: {
        adsList({ adverts }, getters, { filter }) {
            return adverts.filter((el) => {
                return getSearchResultOnRow(filter, el);
            });
        },
    },
    mutations: {
        Get_Ads_List(state, payload) {
            state.adverts = payload;
        },
        Update_Ads_Dto_Date(state, payload) {
            state.advertDto = {
                ...payload,
            };
        },
        Add_Ads(state, payload) {
            state.adverts.unshift(payload);
        },
        Reset_Ads_Dto(state) {
            Object.assign(state.adverts, {
                title: "",
                priority: "",
                startDate: "",
                endDate: "",
                url: "",
            });
        },
        IS_DIALOG_OPEN(state, payload) {
            state.isDialogOpen = payload;
        },
        SET_ADS_DTO(state, payload) {
            if (payload) state.advertDto = { ...payload };
            else
                state.advertDto = {
                    title: "",
                    priority: "",
                    startDate: "",
                    endDate: "",
                    url: "",
                };
        },
        Update_Ads(state, payload) {
            let dto = state.adverts.find((ele) => ele.id == payload.id);
            Object.assign(dto, payload);
        },
        Delete_Ads(state, id) {
            state.adverts.splice(
                state.adverts.findIndex((item) => item.id == id),
                1
            );
        },
    },
    actions: {
        getAdsList({ commit }) {
            api.get("Ad/Dash/GetAll", ({ data }) => {
                commit("Get_Ads_List", data);
            });
        },
        addAds({ commit }, payload) {
            return new Promise((resolve) => {
                api.post(
                    "Ad/Dash/Add",
                    payload,
                    (res) => {
                        commit("Add_Ads", res.data);
                        commit("SET_ADS_DTO");
                        resolve(res);
                    },
                    {
                        success: "تم إضافة الإعلان بنجاح",
                        error: "فشل إضافة الاعلان",
                    }
                );
            });
        },
        updateAds({ commit }, payload) {
            return new Promise((resolve) => {
                api.post(
                    "Ad/Dash/Modify",
                    payload,
                    (res) => {
                        commit("Update_Ads", res.data);
                        commit("SET_ADS_DTO");
                        router.push("/admin/adverts");
                        resolve(res);
                    },
                    {
                        success: "تم تعديل الإعلان بنجاح",
                        error: "فشل التعديل ",
                    }
                );
            });
        },
        deleteAd({ commit }, id) {
            api.delete(
                `Ad/Dash/Delete?id=${id}`,
                () => {
                    commit("Delete_Ads", id);
                },
                {
                    confirm: " هل تريد بالتأكيد حذف الاعلان",
                    success: "تم حذف الاعلان بنجاح",
                    error: "فشل حذف الاعلان ..يرجى اعادة المحاولة ",
                },
                []
            );
        },
    },
};
