<template>
  <ValidationProvider ref="prov" name="phonenumber" :rules="rules" v-slot="props">
    <label class="col-form-label" v-if="label">{{ label }}</label>

    <div class="input-phone">
      <div class="position-relative d-flex dir-ltr p-0">
        <b-button
          @click="dropdown = !dropdown"
          variant="transparent"
          style="background-color: #fff"
          class="selected-country rounded-left p-25 border d-flex align-items-center"
        >
          <img
            :src="'/media/flags/' + selectedCountry.code + '.svg'"
            height="20px"
            width="30px"
          />
          <span class="mx-25">{{ selectedCountry.dial_code }}</span>
          <unicon name="angle-down" fill="#ccc"></unicon>
        </b-button>
        <div class="input w-100 flex-grow">
          <b-form-input
            :state="!props.validated ? null : props.valid ? true : false"
            style="font-size: 15px; letter-spacing: 1px"
            placeholder="Enter Phone Number"
            type="number"
            v-model="phoneNumberVal"
            class="border phone-input"
            :class="
              !props.validated ? '' : props.valid ? 'border-success' : 'border-danger'
            "
          ></b-form-input>
        </div>
        <div class="dropDown d-none position-absolute" :class="{ 'd-block': dropdown }">
          <b-form-input class="w-100 shadow" v-model="key" placeholder="ابحث عن دولة" />
          <ul
            v-if="dropdown"
            id="list"
            class="countries-list p-50 w-50"
            style="list-style: none; background-color: #fff"
          >
            <li
              class="country-item"
              v-for="item in countriesList"
              :key="item.name"
              @click="setActiveCountry(item)"
            >
              <b-img
                style="height: 20px; width: 30px"
                :src="'/media/flags/' + item.code + '.svg'"
              ></b-img>
              <span>
                {{ item.name.slice(0, 10) }}
                ({{ item.dial_code }})
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <small class="text-danger" v-if="props.valid && props.validated">{{
      props.errors[0]
    }}</small>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
let countries = require("./countries.json");

import { extend } from "vee-validate"; //import extend function from vee-validate

extend("mob", {
  message: "Mobile number is not valid",
  validate: (value, param) => {
    return param[0] == "true"; //Test the regex. Test function returns a true or false value.
  },
});

export default {
  components: {
    ValidationProvider,
  },
  created() {},

  props: {
    value: null,
    label: String,
    errorMessage: {
      type: String,
      default: "يرجى التحقق من صحة الرقم",
    },
    required: Boolean,
  },

  data() {
    return {
      selectedCountry: {
        name: "Syrian Arab Republic",
        dial_code: "+963",
        code: "SY",
      },
      isPhoneNumberValid: null,
      innerVal: "",
      phoneNumberVal: "",
      dropdown: false,
      key: "",
    };
  },

  computed: {
    rules() {
      if (this.required) return `mob:${this.isPhoneNumberValid}|required`;
      else return `mob:${this.isPhoneNumberValid}`;
    },
    countriesList() {
      if (!this.key) return countries;
      return countries.filter((el) => {
        if (
          el.name.toLowerCase().indexOf(this.key.toLowerCase()) != -1 ||
          el.dial_code.indexOf(this.key) != -1
        )
          return el;
      });
    },
  },
  methods: {
    setActiveCountry(country) {
      this.selectedCountry = country;
      this.setInnerVal();
      this.dropdown = false;
    },
    handleInput(e) {
      this.phoneNumberVal = e;
    },

    setInnerVal() {
      this.innerVal = this.selectedCountry.dial_code + " " + this.phoneNumberVal;
      this.$emit("input", this.innerVal);
    },
    validate(num) {
      const number = phoneUtil.parseAndKeepRawInput(num, this.selectedCountry.code);

      this.isPhoneNumberValid = phoneUtil.isValidNumberForRegion(
        number,
        this.selectedCountry.code
      );
    },
  },
  watch: {
    phoneNumberVal(nv) {
      console.log("nv :>> ", nv);
      if (nv.length > 1 && nv.length <= 15) {
        this.validate(nv);
      } else {
        this.isPhoneNumberValid = null;
      }
      this.setInnerVal();
    },
    value(nv) {
      this.phoneNumberVal = nv.split(" ")[1];
      this.selectedCountry = this.countriesList.find(
        (el) => el.dial_code == nv.split(" ")[0]
      );
      this.setInnerVal();
    },

    // country(nv) {
    //     this.innerVal = `${nv}-${this.phone} `;
    //     if (this.phone) this.$emit("input", this.innerVal);
    // }
  },
  mounted() {
    if (this.value) {
      this.phoneNumberVal = this.value.split(" ")[1];
      this.selectedCountry = this.countriesList.find(
        (el) => el.dial_code == this.value.split(" ")[0]
      );

      this.setInnerVal();
    }
    // addEventListener("mouseup", (e) => {
    //   console.log(e.target);
    //   if (e.target.id != "list") {
    //     this.dropdown = false;
    //   }
    // });
  },
};
</script>

<style lang="scss">
.input-phone {
  [dir="rtl"] .inp {
    flex-direction: row-reverse;
  }
  .dropDown {
    z-index: 100;
    width: 100%;
    @media (max-width: 768px) {
      width: 100%;
    }
    top: 100%;
    .countries-list {
      border-radius: 5px;
      padding: 0.8rem;
      left: 0;
      min-width: 100%;
      max-height: 200px;
      overflow: auto;
      li {
        width: 100%;
        padding: 0.5rem;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  [dir="rtl"] .dir-ltr,
  .dir-ltr {
    direction: ltr !important ;

    li {
      text-align: left;
    }
  }

  .selected-country {
    border-radius: 0px 5px 5px 0px !important;
    border-right: none !important;
  }
  .phone-input {
    border-radius: 5px 0px 0px 5px !important;
  }
}
</style>
